import { FeatureData } from '@innedit/innedit';
import { DocumentType, ReservationType } from '@innedit/innedit-type';
import { PageProps } from 'gatsby';
import React, { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Reservation from '../../containers/Reservation';
import TemplateUser from '../../templates/user';
import requireUser, { UserProps } from '../../utils/requireUser';

const PageUserCalendrier: FC<PageProps & UserProps> = ({
  params: { espaceId },
  user,
}) => {
  const [reservations, setReservations] =
    useState<DocumentType<ReservationType>[]>();

  useEffect(() => {
    let isMounted = true;
    const featureData = new FeatureData<ReservationType>({
      espaceId,
      collectionName: 'reservations',
    });

    featureData
      .find({
        wheres: {
          user: user.id,
        },
      })
      .then(docs => {
        if (isMounted) {
          setReservations(docs);
        }

        return true;
      })
      .catch(toast.error);

    return () => {
      isMounted = false;
    };
  }, [user.id]);

  return (
    <TemplateUser title="Mes réservations">
      {reservations && reservations.length > 0 && (
        <ul>
          {reservations.map(reservation => (
            <Reservation key={reservation.id} data={reservation} />
          ))}
        </ul>
      )}
      {reservations && 0 === reservations.length && (
        <div>Aucune reservation</div>
      )}
    </TemplateUser>
  );
};

export default requireUser(PageUserCalendrier);
