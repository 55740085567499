import { DocumentType, SlotType } from '@innedit/innedit-type';
import dayjs from 'dayjs';
import compact from 'lodash/compact';
import React, { ElementType, FC } from 'react';
import { useTranslation } from 'react-i18next';

dayjs.locale('fr');

export const calcEnd = (
  startTime: string,
  duration?: string,
): string | null => {
  if (!duration) {
    return null;
  }

  const [startH, startM] = startTime.split(':');
  const [durationH, durationM] = duration.split(':');

  return `${Number(startH) + Number(durationH)}:${String(
    Number(startM) + Number(durationM),
  ).padStart(2, '0')}`;
};

interface SlotProps {
  as?: ElementType;
  data: DocumentType<SlotType>;
  showDate?: boolean;
  showPlaces?: boolean;
}

const Slot: FC<SlotProps> = ({
  as,
  data,
  showDate = true,
  showPlaces = true,
}) => {
  const { t } = useTranslation();

  const { date, day, duration, isRecurring, quantity, start } = data;

  if (!showDate && !showPlaces) {
    return React.createElement(
      as ?? React.Fragment,
      {},
      compact([start, calcEnd(start, duration)]).join(' - '),
    );
  }

  const info = (
    <div className="flex justify-between">
      <span>{compact([start, calcEnd(start, duration)]).join(' - ')}</span>
      {showPlaces && (
        <span className="text-light-800 italic">
          {t('slot.places', { count: quantity })}
        </span>
      )}
    </div>
  );

  if (!showDate) {
    return React.createElement(as ?? React.Fragment, {}, info);
  }

  return React.createElement(
    as ?? React.Fragment,
    {},
    <div className="flex flex-col w-full">
      <span>
        {isRecurring
          ? `tous les ${t(`days.${day}`)}s :`
          : dayjs(date).format('dddd D MMM YYYY')}
      </span>
      {info}
    </div>,
  );
};

export default Slot;
