import { FeatureData, ProduitData } from '@innedit/innedit';
import {
  DocumentType,
  ProduitType,
  ReservationType,
  SlotType,
} from '@innedit/innedit-type';
import dayjs from 'dayjs';
import React, { FC, useEffect, useState } from 'react';

import Button from '../../components/Button';
import Slot from '../../components/Slot';

interface ReservationProps {
  data: DocumentType<ReservationType>;
}

const Reservation: FC<ReservationProps> = ({ data }) => {
  const [produit, setProduit] = useState<DocumentType<ProduitType>>();
  const [slot, setSlot] = useState<DocumentType<SlotType>>();
  const [error, setError] = useState<string>();

  useEffect(() => {
    let isMounted = true;

    if (data) {
      const { boutique: espaceId, produit: produitId, slot: slotId } = data;
      const produitData = new ProduitData({ espaceId });

      produitData
        .findById(produitId)
        .then(document => {
          if (isMounted) {
            setProduit(document);

            if (slotId) {
              const slotData = new FeatureData<SlotType>({
                espaceId,
                collectionName: `produits/${produitId}/slots`,
              });

              return slotData.findById(slotId);
            }
          }

          return undefined;
        })
        .then(documentSlot => {
          if (isMounted && documentSlot) {
            setSlot(documentSlot);
          }

          return true;
        })
        .catch(e => setError(e.message));
    }

    return () => {
      isMounted = false;
    };
  }, [JSON.stringify(data)]);

  if (error) {
    return <div>{error}</div>;
  }

  const { boutique, slotDate } = data;

  return (
    <li className="flex space-x-3">
      <span>{dayjs(slotDate).format('dddd D MMM YYYY')}</span>
      {produit && (
        <Button to={`/${boutique}/${produit.id}/`} variant="link">
          {produit.name}
        </Button>
      )}
      {slot && (
        <Slot as="span" data={slot} showDate={false} showPlaces={false} />
      )}
    </li>
  );
};

export default Reservation;
